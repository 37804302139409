import React from "react"

const Logo = ({ height = "80px", style = {} }) => (
  <svg
    id="rachio-pro-logo"
    data-name="Rachio Pro Logo"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="rachio-pro-logo"
    style={{
      height,
      ...style,
    }}
    viewBox="0 0 1461.08 507.99"
  >
    <title id="rachio-pro-logo-title">Rachio Pro Logo</title>
    <g>
      <g>
        <path fill="#25aaff" d="M239.99,394.85c-15.9,0-28.79-12.89-28.79-28.79v-1.6h28.79v30.39Z"/>
        <path fill="#25aaff" d="M239.99,314.88v30.39h-60.13c-6.54,0-11.85-5.3-11.85-11.85v-18.54h71.97Z"/>
        <path fill="#25aaff" d="M211.2,265.3h0c15.9,0,28.79,12.89,28.79,28.79v1.6h-117.71c-6.54,0-11.85-5.3-11.85-11.85v-18.54h100.76Z"/>
      </g>
      <g>
        <path fill="#25aaff" d="M139.23,213.9h1.6v28.79h-30.39c0-15.9,12.89-28.79,28.79-28.79Z"/>
        <path fill="#25aaff" d="M160.02,182.56c0-6.54,5.3-11.85,11.85-11.85h18.54v71.97h-30.39v-60.13Z"/>
        <path fill="#25aaff" d="M211.2,242.69h-1.6v-117.71c0-6.54,5.3-11.85,11.85-11.85h18.54v100.77c0,15.9-12.89,28.79-28.79,28.79Z"/>
      </g>
      <g>
        <path fill="#25aaff" d="M291.39,141.93v1.6h-28.79v-30.39h0c15.9,0,28.79,12.89,28.79,28.79Z"/>
        <path fill="#25aaff" d="M322.73,162.72c6.54,0,11.85,5.3,11.85,11.85v18.54h-71.97v-30.39h60.13Z"/>
        <path fill="#25aaff" d="M380.31,212.3c6.54,0,11.85,5.3,11.85,11.85v18.54h-100.76c-15.9,0-28.79-12.89-28.79-28.79v-1.6h117.71Z"/>
      </g>
      <g>
        <path fill="#25aaff" d="M363.36,294.09h-1.6v-28.79h30.39c0,15.9-12.89,28.79-28.79,28.79Z"/>
        <path fill="#25aaff" d="M342.57,325.43c0,6.54-5.3,11.85-11.85,11.85h-18.54v-71.97h30.39v60.13Z"/>
        <path fill="#25aaff" d="M291.39,265.3h1.6v117.71c0,6.54-5.3,11.85-11.85,11.85h-18.54v-100.76c0-15.9,12.89-28.79,28.79-28.79Z"/>
      </g>
    </g>
    <g>
      <path fill="#001016" d="M440.81,196.71h26.1v20.03c5.62-13.5,19.58-21.6,37.8-21.6h4.05v24.98h-8.55c-20.03,0-33.3,12.6-33.3,39.6v50.63h-26.1v-113.63Z"/>
      <path fill="#001016" d="M512.13,253.41c0-33.53,25.65-59.4,58.5-59.4,15.98,0,29.7,6.3,39.6,16.88l-.23-14.4,26.1.23v113.63h-26.1l.23-14.18c-9.9,10.12-23.63,16.88-39.6,16.88-32.85,0-58.5-26.55-58.5-59.63ZM538.23,253.41c0,20.25,16.2,36.68,36,36.68s36-16.43,36-36.68-15.98-36.45-36-36.45-36,15.98-36,36.45Z"/>
      <path fill="#001016" d="M703.16,194.01c24.08,0,42.08,11.7,52.2,29.03l-20.25,11.48c-5.63-10.58-17.1-18-31.28-18-20.03,0-36,16.65-36,36.9s15.98,36.9,36,36.9c13.73,0,25.43-7.2,31.05-17.78l21.15,11.48c-9.67,16.43-28.35,29.03-52.65,29.03-34.43,0-61.65-26.55-61.65-59.85s27.23-59.18,61.43-59.18Z"/>
      <path fill="#001016" d="M762.33,310.33v-159.75h26.1v56.48c7.88-8.33,18.9-13.28,32.63-13.28,27,0,46.8,18.9,46.8,45.9v70.65h-26.1v-65.48c0-17.33-10.35-27.9-26.33-27.9s-27,11.25-27,29.93v63.45h-26.1Z"/>
      <path fill="#001016" d="M891.03,148.33c9.68,0,16.2,6.53,16.2,15.75s-6.53,15.75-16.2,15.75-16.2-6.53-16.2-15.75,6.98-15.75,16.2-15.75ZM904.08,196.71v113.63h-26.1v-113.63h26.1Z"/>
      <path fill="#001016" d="M972.93,194.01c34.2,0,61.43,26.1,61.43,59.18s-27.23,59.85-61.43,59.85-61.65-26.55-61.65-59.85,27.23-59.18,61.65-59.18ZM972.93,289.63c19.8,0,35.55-15.98,35.55-36.23s-15.75-36.23-35.55-36.23-35.78,15.98-35.78,36.23,15.75,36.23,35.78,36.23Z"/>
    </g>
    <g>
      <path fill="#25aaff" d="M1058.68,196h39.54c23.17,0,39.7,16.69,39.7,37.27s-16.53,37.43-39.7,37.43h-16.53v38.73h-23.01v-113.43ZM1081.69,215.61v35.97h14.1c10.86,0,19.12-7.61,19.12-18.15s-8.26-17.82-19.12-17.82h-14.1Z"/>
      <path fill="#25aaff" d="M1144.71,309.43v-113.43h42.78c23.01,0,40.02,15.23,40.02,35.81,0,14.91-8.91,27.87-23.01,33.87l28.19,43.75h-24.95l-24.95-40.35h-15.23v40.35h-22.85ZM1167.56,250.28h19.93c9.56,0,17.34-7.29,17.34-17.18,0-9.24-7.78-16.85-17.34-16.85h-19.93v34.03Z"/>
      <path fill="#25aaff" d="M1290.69,193.89c34.19,0,59.95,25.6,59.95,58.82s-25.76,58.82-59.95,58.82-59.95-25.6-59.95-58.82,25.76-58.82,59.95-58.82ZM1290.69,290.14c20.58,0,36.94-16.37,36.94-37.43s-16.37-37.43-36.94-37.43-36.94,16.37-36.94,37.43,16.37,37.43,36.94,37.43Z"/>
    </g>
  </svg>
)

export default Logo
